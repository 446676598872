import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfoAlertPageModule } from './shared/pages/info-alert/info-alert.module';
import { AlertService, PyroInterceptor } from '@bob/service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { DEFAULT_MAP_CENTER } from '@bob/api';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    InfoAlertPageModule,
    GoogleMapsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: PyroInterceptor, multi: true },
    { provide: 'GOOGLE_MAPS_API_KEY', useValue: DEFAULT_MAP_CENTER },
    TranslateService,
    IonicStorageModule,
    AlertService,
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
