import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AlertService, AuthService } from '@bob/service';
import { environment } from '@bob/api';

@Injectable()
export class BobInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private alertService: AlertService, private translate: TranslateService) {}

  getNewRequest(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (request.url.search('googleapis') > -1) {
      return request;
    }
    if (request.url.indexOf('http') !== -1) {
      return request;
    }
    if (request.url.indexOf('api') === -1) {
      return request;
    }

    let domain = environment.newApi;
    domain = domain.slice(-1) === '/' ? domain.substring(0, domain.length - 1) : domain;
    let api = request.url;
    api = api.substring(0, 1) === '/' ? api.substring(1, api.length) : api;

    const url = `${domain}/${api}`;

    const httpHeaders = {
      accept: 'application/json',
      authorization: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json; charset=utf-8'
    };

    if (token && token.length > 0 && request.url.search('SendPassword') === -1) {
      httpHeaders.authorization = 'Bearer ' + token;
    }

    request = request.clone({ setHeaders: httpHeaders, url });

    return request;
  }

  private async getValidToken(request: HttpRequest<any>): Promise<string | null> {
    if (
      request.url === '/api/Hesap/Token' ||
      request.url === '/api/Hesap/RefreshToken' ||
      request.url.indexOf('api') === -1 ||
      request.url.indexOf('/api/JobNotification/SendPassword') > -1
    ) {
      return null;
    }
    let token = await this.authService.getValidToken();
    if (!token) {
      await this.authService.refreshToken();
      token = await this.authService.getValidToken();
    }
    return token;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.getValidToken(request)).pipe(
      switchMap((token: string | null) => {
        request = this.getNewRequest(request, token);
        return next.handle(request).pipe(
          tap(
            async (response: HttpEvent<any>) => {
              // if (response instanceof HttpResponse<any>) console.log(response.url, response.status);
            },
            async (error: HttpErrorResponse) => {
              let mesaj: string;
              switch (error.status) {
                case 0:
                  mesaj = this.translate.instant('alert.noAccessApi');
                  await this.alertService.presentToast(mesaj);
                  break;

                case 400:
                  mesaj = error.error?.Message;
                  this.alertService.presentToast(mesaj);
                  break;

                case 401:
                case 403:
                  mesaj = this.translate.instant('alert.authFailed');
                  await this.alertService.presentToast(mesaj);
                  this.authService.logout();
                  break;

                default:
                  mesaj = error.error?.Message;
                  if (!mesaj) mesaj = error?.message;
                  if (!mesaj) mesaj = error.error?.title;
                  console.log(mesaj);

                  this.alertService.presentToast(mesaj);
                  break;
              }
            }
          )
        );
      })
    );
  }
}
