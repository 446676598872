import { Injectable } from '@angular/core';
import { PreferencesPlugin, Preferences, GetResult, ConfigureOptions, KeysResult, MigrateResult } from '@capacitor/preferences';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements PreferencesPlugin {
  private isMobile: boolean;

  constructor(private platform: Platform) {
    this.isMobile = false; //this.platform.is('ios') || this.platform.is('android');
  }

  async get(options: { key: string }): Promise<GetResult> {
    if (this.isMobile) {
      const value = localStorage.getItem(options.key);
      return Promise.resolve({ value });
    } else {
      const value = await Preferences.get(options);
      return Promise.resolve(value);
    }
  }

  async set(options: { key: string; value: any }): Promise<void> {
    if (this.isMobile) {
      localStorage.setItem(options.key, options.value);
      return Promise.resolve();
    } else {
      await Preferences.set(options);
    }
  }

  async remove(options: { key: string }): Promise<void> {
    if (this.isMobile) {
      localStorage.removeItem(options.key);
      return Promise.resolve();
    } else {
      await Preferences.remove(options);
    }
  }

  configure(options: ConfigureOptions): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async clear(): Promise<void> {
    if (this.isMobile) {
      localStorage.clear();
      return Promise.resolve();
    } else {
      return Preferences.clear();
    }
  }

  keys(): Promise<KeysResult> {
    throw new Error('Method not implemented.');
  }

  migrate(): Promise<MigrateResult> {
    throw new Error('Method not implemented.');
  }

  removeOld(): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
