import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as Model from '../models/bob-vale.model';
import { BaseResponse } from '../models/base.model';

@Injectable({
  providedIn: 'root'
})
export class BobvaleService {
  constructor(private _httpClient: HttpClient) {}

  getContinuesJobs(body: Model.JobModelReq): Observable<Model.hizmetlerModel[]> {
    return this._httpClient.post<Model.hizmetlerModel[]>('/api/bobvale/GetMyContinuesJobs', body);
  }

  iyzicoPayment(body: Model.IyzicoPaymentModel): Observable<Model.IyzicoPaymentResponseModel> {
    return this._httpClient.post<Model.IyzicoPaymentResponseModel>('/api/bobvale/IyzicoPayment', body);
  }

  checkPaymentResult(basketid: string): Observable<any> {
    return this._httpClient.get<any>('/api/bobvale/CheckPaymentResult?basketid=' + basketid);
  }

  getCallVehicleInfo(guid: string | null): Observable<Model.CarSelectModel> {
    guid = guid ? guid : '';
    return this._httpClient.get<Model.CarSelectModel>(`/api/vale/AracimiCagirBilgi/${guid}`);
  }

  getCallCarViaGuid(guid: string, teslimKapisi: number): Observable<Model.CarSelectModel> {
    return this._httpClient.get<Model.CarSelectModel>(`/api/vale/AracimiCagir/${guid}/${teslimKapisi}`);
  }

  checkAdvantageValueOthersNew(model: Model.AdvantageModelOther): Observable<Model.AdvantageResult> {
    return this._httpClient.post<Model.AdvantageResult>('/api/bobvale/CheckAdvantageValueOthersNew', model);
  }

  checkAdvantageValueNew(model: Model.AdvantageModel): Observable<Model.AdvantageResult> {
    return this._httpClient.post<Model.AdvantageResult>('/api/bobvale/CheckAdvantageValueNew', model);
  }

  checkAdvantageValue(model: Model.AdvantageModel): Observable<Model.AdvantageResult> {
    return this._httpClient.post<Model.AdvantageResult>('/api/bobvale/CheckAdvantageValue', model);
  }

  checkAdvantageValueOthers(model: Model.AdvantageModelOther): Observable<Model.AdvantageResult> {
    return this._httpClient.post<Model.AdvantageResult>('/api/bobvale/CheckAdvantageValueOthers', model);
  }

  getVehicleList(musteriId: number): Observable<Model.GetVehicleListModel[]> {
    return this._httpClient.get<any>('/api/bobvale/GetVehicleList?musteriId=' + musteriId).pipe();
  }

  deleteVehicle(body: Model.DeleteVehicleModel): Observable<boolean> {
    return this._httpClient.post<boolean>('/api/bobvale/DeleteVehicle', body).pipe();
  }

  vehicleProcess(funcName: string, body: Model.VehicleModel): Observable<BaseResponse<any>> {
    return this._httpClient.post<BaseResponse<any>>('/api/bobvale/' + funcName, body).pipe();
  }

  getVehicleBrands(): Observable<Model.VehicleBrandModel[]> {
    return this._httpClient.get<Model.VehicleBrandModel[]>('/api/bobvale/GetVehicleBrands').pipe();
  }

  getVehicleModels(markaId: string): Observable<Model.VehicleModelsModel[]> {
    return this._httpClient.get<Model.VehicleModelsModel[]>('/api/bobvale/GetVehicleModels?markaId=' + markaId).pipe();
  }

  getVehicleColors(): Observable<Model.VehicleColorsModel[]> {
    return this._httpClient.get<Model.VehicleColorsModel[]>('/api/bobvale/GetVehicleColors').pipe();
  }

  packetSum(musteriId: number): Observable<number> {
    return this._httpClient.get<number>('/api/bobvale/PacketSum?musteriid=' + musteriId).pipe();
  }

  saveNewInvoice(body: Model.NewInvoiceModel): Observable<BaseResponse<any>> {
    return this._httpClient.post<BaseResponse<any>>('/api/bobvale/SaveNewInvoice', body).pipe();
  }

  registerCustomer(body: Model.RegisterCustomerModel): Observable<BaseResponse<Model.RegisterCustomerDataModel>> {
    return this._httpClient.post<BaseResponse<Model.RegisterCustomerDataModel>>('/api/bobvale/RegisterCustomer', body).pipe();
  }

  getPackets(): Observable<Model.PackageModel[]> {
    return this._httpClient.get<Model.PackageModel[]>('/api/bobvale/getpackets').pipe();
  }
  changeAutoReloadStatus(status: boolean): Observable<Model.ChangeAutoModel> {
    return this._httpClient.get<Model.ChangeAutoModel>('/api/bobvale/ChangeAutoReloadStatus/' + status).pipe();
  }
}
