import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse, RegisterCustomerDataModel, RegistrationReq } from '@bob/api';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  public policiesTrObj = {
    policiesText: 'buradan',
    policies: 'Kişisel verilerinizin işlenmesi hakkında detaylı bilgiye',
    policiesEnd: 'ulaşabilirsiniz.',
    etkText: 'buradan',
    etk: 'BoB Vale Hizmetleri ile ilgili kampanya, promosyon ve tanıtım içerikli ticari elektronik ileti gönderilmesine ve beğeni, kullanım alışkanlıkları ve tercihlerime göre bana özel teklifler sunulması amacıyla kimlik, iletişim, araç ve müşteri işlem bilgilerimin işlenmesine rıza veriyorum. Kişisel verilerinizin işlenmesi hakkında detaylı bilgiye',
    etkEnd: 'ulaşabilirsiniz.'
  };

  public policiesEnObj = {
    policiesText: 'here.',
    policies: 'You may find detailed information about the processing of your personal data',
    etk: 'I consent to the processing of my identity data, contact data, data relating my vehicle, and customer transaction information in order to receive commercial electronic messages on special offers, promotions, and advertisements about Bob Vale Services and to receive personalized offers based on my preferences, usage habits and choices.'
  };

  constructor(private _httpClient: HttpClient) {}

  registerCustomer(body: RegistrationReq): Observable<BaseResponse<RegisterCustomerDataModel>> {
    return this._httpClient.post<BaseResponse<RegisterCustomerDataModel>>('/api/JobNotification/RegisterCustomer', body);
  }
}
