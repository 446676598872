import { BaseResponse } from '@bob/api';

export interface CheckRewardProgramNewRequestModel {
  userId: number;
  rewardProgramId?: number | undefined;
  code: string;
  price: number;
}

export interface CheckRewardProgramNewResponseModel {
  isOk: boolean;
  transactionId: string;
  returnMessage: string;
  message: string;
  messageEn: string;
  price: number;
}
export class PassengerListModel {
  public email: string | undefined;
  public nameLastName: string;
  public nationalityId: string;
  public passengerNationality: string;
  public passengerType: number;
  public phone: string;
  public gender: number | undefined;

  constructor(item?: any) {
    this.email = item?.email || undefined;
    this.nameLastName = item?.nameLastName || undefined;
    this.nationalityId = item?.nationalityId || undefined;
    this.passengerNationality = item?.passengerNationality || undefined;
    this.passengerType = item?.passengerType || undefined;
    this.phone = item?.phone || undefined;
    this.gender = item?.gender || undefined;
  }
}

export interface ReservationDetailModel {
  adultCount: number;
  childCount: number;
  currency: string;
  description: string;
  flightNo: string;
  fromAddress: string;
  fromLatitude: number;
  fromLongitude: number;
  fromRegion: string;
  language: string;
  languageID: number;
  price: number;
  reservationDate: string;
  serviceType: number;
  tempId: number;
  toAddress: string;
  toLatitude: number;
  toLongitude: number;
  toRegion: string;
  totalCost: number;
  transferKm: number;
  vatRatio: number;
  serviceId: number;
  vehicleTypeId: number;
  departure_Landing: string;
}

export interface AddReservationModel {
  cardToken: string | null;
  creditCardId: number;
  description: string;
  discountAmount: number;
  discountExists: boolean;
  discountRate: number;
  extraServiceList: any[];
  invoiceAddressId: number;
  paidRoute: any[];
  passengerList: PassengerListModel[];
  reservationDetail: ReservationDetailModel;
  rewardProgramCode: string;
  rewardProgramId: number;
  rewardProgramTransactionId: string;
  totalCost: number;
  userId: number | undefined;
  isCuzdan: boolean;
}

export interface AddReservationRequestModel2 {
  userId: number;
  invoiceAddressId: number;
  description?: string;
  rewardProgramCode?: string;
  discountExists: boolean;
  totalCost?: number;
  serviceId?: number;
  paidRoute: number[];
  discountAmount?: number;
  discountRate?: number;
  reservationDetail: ReservationDetailModel;
  passengerList: PassengerListModel[];
  extraServiceList: ReservationDetailExtra[];
}

export interface ReservationDetailExtra {
  price?: number;
  currency: string;
  vatRatio?: number;
  vatCost?: number;
  totalCost?: number;
  extraServiceId: number;
  serviceName: string;
  quantity?: number;
  extraServiceType: number;
}

export interface AddReservationDataRespModel {
  reservationId: number;
  reservationNo: string;
  message: string;
  messageEn: string;
  responseCode: number;
  userId: number;
  htmlContentFor3DSecure: string;
}

export interface OdemeSonucuModel {
  transactionId: string;
  secure3DPage: string;
}

export interface KopruOtoyolDataModel {
  ad: string;
  boylam: number;
  enlem: number;
  gorunur: boolean;
  id: number;
  silindi: boolean;
}

export class ReservationPaymentModel {
  public userId: number;
  public reservationId: number;

  constructor(item?: any) {
    this.userId = item?.userId || undefined;
    this.reservationId = item?.reservationId || undefined;
  }
}

export class DriverSurveyReq {
  public hizmetId: string;
  public puan: number;
  public olusturanKullaniciId: number;

  constructor(item: any) {
    this.hizmetId = item.hizmetId || '';
    this.puan = item.puan || 0;
    this.olusturanKullaniciId = item.olusturanKullaniciId || 0;
  }
}

export interface GetUserRespModel {
  code: number;
  data: number;
  message: string;
  messageEn: string;
}

export class DriverSurveyModel {
  public isOk: boolean;
  public message: string;
  public messageEn: string;

  constructor(item?: any) {
    this.isOk = item?.isOk || false;
    this.message = item?.message || false;
    this.messageEn = item?.messageEn || false;
  }
}
export interface CancelReservationReq {
  userId: number;
  reservationId: number;
  reservationDetailId: number;
  paymentId: string;
}
export class GetDriverRespModel {
  public data: GetDriverDetailsModel;

  constructor(item?: any) {
    this.data = item?.data || undefined;
  }
}

export class GetDriverDetailsModel {
  public isOk: boolean;
  public message: string;
  public messageEn: string;
  public soforAdSoyad: string;
  public soforTelefon: string;
  public soforHESKod: string;
  public aracKisaAd: string;
  public aracKisaAdEN: string;
  public aracPlaka: string;
  public karsilayiciAdi: string;
  public karsilayiciTelefon: string;
  public karsilayiciCinsiyet: string;
  public karsilayiciCinsiyetEN: string;

  constructor(item: any) {
    this.isOk = item.isOk || false;
    this.message = item.message || '';
    this.messageEn = item.messageEn || '';
    this.soforAdSoyad = item.soforAdSoyad || '';
    this.soforTelefon = item.soforTelefon || '';
    this.soforHESKod = item.soforHESKod || '';
    this.aracKisaAd = item.aracKisaAd || '';
    this.aracKisaAdEN = item.aracKisaAdEN || '';
    this.aracPlaka = item.aracPlaka || '';
    this.karsilayiciAdi = item.karsilayiciAdi || '';
    this.karsilayiciTelefon = item.karsilayiciTelefon || '';
    this.karsilayiciCinsiyet = item.karsilayiciCinsiyet || '';
    this.karsilayiciCinsiyetEN = item.karsilayiciCinsiyetEN || '';
  }
}
export class CheckFlightModel extends BaseResponse<CheckFlightDataModel> {
  constructor(item: any) {
    super(item);
    this.data = item.data;
  }
}

export class CheckFlightDataModel {
  public havayoluFirma: string;
  public ucusNereden: string;
  public ucusNereye: string;
  public ucusKalkisSaat: string;
  public ucusVarisSaat: string;

  constructor(item?: any) {
    this.havayoluFirma = item?.havayoluFirma || undefined;
    this.ucusNereden = item?.ucusNereden || undefined;
    this.ucusNereye = item?.ucusNereye || undefined;
    this.ucusKalkisSaat = item?.ucusKalkisSaat || undefined;
    this.ucusVarisSaat = item?.ucusVarisSaat || undefined;
  }
}

export interface GetVehicleListRequestModel {
  // Transfer hizmet tip KM: 2, transferServiceType
  userId: number;

  // regionCode?: string; // Eğer kullanılacaksa yorumdan çıkarabilirsiniz.
  adultCount: number;

  childCount: number;
  fromLatitude: number;
  fromLongitude: number;
  toLatitude: number;
  toLongitude: number;
  transferKm: number;
  reservationDate: Date;
  serviceType: number; // 100: Transfer, 101: Şöförlü Araç
  rewardProgramId: number;
  fromAirport?: string;
  toAirport?: string;
  email?: string;
  isReturn: boolean;
  flightCode?: string;
  serviceId?: number;
  paidRoute?: number[];
  /**
   * @deprecated
   * Servise gitmiyor
   */
  rentPackage?: any;
  /**
   * @deprecated
   * Servise gitmiyor
   */
  polyLine?: any;
  /**
   * @deprecated
   * Servise gitmiyor
   */
  fromAddress?: any;
  /**
   * @deprecated
   * Servise gitmiyor
   */
  toAddress?: any;
}

export class AddressModel {
  public input: string;
  public region: string;
  public country_long: string;
  public country_short: string;
  public position: PositionModel;
  public isAirport: boolean;
  public airportInfo: AirportInfoModel;

  constructor(item?: any) {
    this.input = item?.input || '';
    this.region = item?.region || '';
    this.country_long = item?.country_long || undefined;
    this.country_short = item?.country_short || undefined;
    this.position = item?.position || undefined;
    this.isAirport = item?.isAirport || undefined;
    this.airportInfo = item?.airportInfo || new AirportInfoModel({});
  }
}

export class PositionModel {
  public lat: number;
  public lng: number;

  constructor(item: any) {
    this.lat = item.lat || 0;
    this.lng = item.lng || 0;
  }
}
export class AirportInfoModel {
  public gates: any[];
  public selectedGate: string;
  public isFlightNoAvailable: boolean;
  public flightNo: string;

  constructor(item: any) {
    this.gates = item.gates || [];
    this.selectedGate = item.selectedGate || '';
    this.isFlightNoAvailable = item.isFlightNoAvailable || false;
    this.flightNo = item.flightNo || '';
  }
}

export class VehicleListRespModel {
  public code: number;
  public message: string;
  public messageEn: string;
  public data: VehicleListDataModel;

  constructor(item: any) {
    this.code = item.code || undefined;
    this.message = item.message || undefined;
    this.messageEn = item.messageEn || undefined;
    this.data = item.data || undefined;
  }
}

export class VehicleListDataModel {
  public currency: string;
  public discountExists: boolean;
  public extraServices: any[];
  public luggageCapacity: number;
  public passengerCapacity: number;
  public preperationTime: number;
  public preperationTimeUnit: string;
  public price: number;
  public priceBeforeDiscount: number;
  public resCorrectionTime: number;
  public vehicleDetails: VehicleListDetailModel[];
  public vehicleShortTypeName: string;
  public vehicleShortTypeNameEn: string;
  public vehicleTypeId: number;
  public vehicleTypeImage: string;
  public vehicleTypeName: string;
  public vehicleTypeNameEn: string;
  public messageEn: string;
  public messageTr: string;
  public updatedPrice: number;
  public extrasTotal: number;

  constructor(item: any) {
    this.currency = item?.currency || undefined;
    this.discountExists = item?.discountExists || undefined;
    this.extraServices = item?.extraServices || [];
    this.luggageCapacity = item?.luggageCapacity || undefined;
    this.passengerCapacity = item?.passengerCapacity || undefined;
    this.preperationTime = item?.preperationTime || undefined;
    this.preperationTimeUnit = item?.preperationTimeUnit || undefined;
    this.price = item?.price || undefined;
    this.priceBeforeDiscount = item?.priceBeforeDiscount || undefined;
    this.resCorrectionTime = item?.resCorrectionTime || undefined;
    this.vehicleDetails = item?.vehicleDetails || [];
    this.vehicleShortTypeName = item?.vehicleShortTypeName || undefined;
    this.vehicleShortTypeNameEn = item?.vehicleShortTypeNameEn || undefined;
    this.vehicleTypeId = item?.vehicleTypeId || undefined;
    this.vehicleTypeImage = item?.vehicleTypeImage || undefined;
    this.vehicleTypeName = item?.vehicleTypeName || undefined;
    this.vehicleTypeNameEn = item?.vehicleTypeNameEn || undefined;
    this.messageEn = item?.messageEn || undefined;
    this.messageTr = item?.messageTr || undefined;
    this.updatedPrice = item?.updatedPrice || undefined;
    this.extrasTotal = item?.extrasTotal || undefined;
  }
}

export class VehicleListDetailModel {
  public info: string;
  public infoEn: string;
  public toolTip: string;
  public toolTipEn: string;

  constructor(item?: any) {
    this.info = item?.info || undefined;
    this.infoEn = item?.infoEn || undefined;
    this.toolTip = item?.toolTip || undefined;
    this.toolTipEn = item?.toolTipEn || undefined;
  }
}
export class HavalimaniKapiRespModel {
  public data: HavalimaniKapiModel[];

  constructor(item?: any) {
    this.data = item?.data || [];
  }
}
export class HavalimaniKapiModel {
  public id: number;
  public hizmetNoktaId: number;
  public ad: string;
  public adEn: string;

  constructor(item?: any) {
    this.id = item?.id || undefined;
    this.hizmetNoktaId = item?.hizmetNoktaId || undefined;
    this.ad = item?.ad || undefined;
    this.adEn = item?.iadEnd || undefined;
  }
}

export class GetHizmetRespModel extends BaseResponse<GetHizmetModel[]> {
  constructor(item: any) {
    super(item);
    this.data = item.data || undefined;
  }
}
export class GetHizmetModel {
  public adi: string;
  public adiEn: string;
  public hazirlikSureBirim: string;
  public hazirliksure: number;
  public hizmetId: number;
  public hizmetKodu: string;
  public hizmetSure: number;
  public hizmetSureBirim: string;

  constructor(item: any) {
    this.adi = item.adi || undefined;
    this.adiEn = item.adiEn || undefined;
    this.hazirlikSureBirim = item.hazirlikSureBirim || undefined;
    this.hazirliksure = item.hazirliksure || undefined;
    this.hizmetId = item.hizmetId || undefined;
    this.hizmetKodu = item.hizmetKodu || undefined;
    this.hizmetSure = item.hizmetSure || undefined;
    this.hizmetSureBirim = item.hizmetSureBirim || undefined;
  }
}

export class GetUserRespDataModel {
  MessageEn: string;
  MessageTr: string;
  Status: number;

  constructor(item?: any) {
    this.MessageEn = item.MessageEn || undefined;
    this.MessageTr = item.MessageTr || undefined;
    this.Status = item.Status || undefined;
  }
}
