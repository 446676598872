export interface KrediKartiModel {
  id: string;
  kartNumarasi: string;
  ad: string;
  imaj: string;
  varsayilan: boolean;
}

export interface KrediKartiKaydetModel {
  ad?: string;
  kartNumarasi: string;
  cvc: string;
  kartAdSoyad: string;
  sonKullanimAy: string;
  sonKullanimYil: string;
  kaydet: boolean;
  varsayilan: boolean;
}

export interface KartSilModel {
  kartId: string;
}

export interface CariDetayModel {
  id: number;
  ad: string;
  vergiNo: string;
  vergiDairesi?: string;
  adres?: string;
  il?: string;
  ilce?: string;
  ulke?: string;
  postaKodu?: string;
  cariTipi: CariTipi;
  grupId: number;
  logo?: Uint8Array;
  eposta?: string;
  telefon?: number;
  mersisNo?: string;
  varsayilan: boolean;
}

export interface CariOlusturModel {
  ad: string;
  vergiNo: string;
  vergiDairesi?: string;
  adres: string;
  il: string;
  ilce: string;
  ulke?: string;
  postaKodu?: string;
  cariTipi: CariTipi;
  eposta: string;
}

export enum CariTipi {
  Bireysel = 0,
  Kurumsal = 1,
  SahisSirket = 2,
  TuzelSirket = 3
}
