import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { GoogleService, LanguageService, ThemeService } from '@bob/service';
import { Platform } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { timer } from 'rxjs';

register();

@Component({
  selector: 'app-bob-frontend-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: false
})
export class AppComponent {
  public showSplash = true;
  private flag = true;
  public googleMapFlag = false;
  public googleMapError = false;

  constructor(
    private platform: Platform,
    private languageService: LanguageService,
    private httpClient: HttpClient,
    private googleService: GoogleService,
    private themeService: ThemeService
  ) {
    // this.storage.create();
    this.initializeApp();
    this.googleService.GoogleInit().subscribe(flag => {
      this.googleMapFlag = flag;
    });
    // Başlangıç temasını ayarla
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // web uygulamasında dark tema hazır değil
    const darkMode = false; // prefersDark.matches;
    this.themeService.setTheme(darkMode);

    // Sistem teması değişikliğini dinle
    prefersDark.addEventListener('change', e => {
      this.themeService.setTheme(e.matches);
    });
  }
  async initializeApp() {
    this.flag = false;
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault(); // Sorulmalı
      this.languageService.setInitialAppLanguage();
      timer(700).subscribe(() => (this.showSplash = false));
    });
  }
}
