import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private isDark = new BehaviorSubject<boolean>(false);
  isDarkTheme$ = this.isDark.asObservable();

  constructor() {
    // Kayıtlı temayı kontrol et
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      this.setTheme(savedTheme === 'dark');
    }
  }

  setTheme(isDark: boolean) {
    this.isDark.next(isDark);
    const theme = isDark ? 'dark' : 'light';
    document.body.className = theme;
    localStorage.setItem('theme', theme);
  }

  toggleTheme() {
    this.setTheme(!this.isDark.value);
  }
}
