import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LanguageService } from '@bob/service';
import { StaticContentDetails, StaticContentType } from '../models/static-content.model';

@Injectable({
  providedIn: 'root'
})
export class StaticContentService {
  private pageDetails: BehaviorSubject<StaticContentDetails> = new BehaviorSubject({
    stCont: 0,
    lang: ''
  });
  private pageStaticContentResponse: BehaviorSubject<StaticContentType> = new BehaviorSubject(new StaticContentType({}));

  public get pageDetails$(): StaticContentDetails {
    return this.pageDetails.value;
  }

  constructor(private _httpClient: HttpClient, private languageService: LanguageService) {}

  fetchDetailsByPage(pageName: string | null): void {
    let stCont = 0;
    let lang = '';
    switch (pageName) {
      case 'data_usage_tr':
        {
          stCont = 1;
          lang = 'tr';
        }
        break;
      case 'data_usage_en':
        {
          stCont = 1;
          lang = 'en';
        }
        break;

      case 'legal_warning_tr':
        {
          stCont = 2;
          lang = 'tr';
        }
        break;
      case 'legal_warning_en':
        {
          stCont = 2;
          lang = 'en';
        }
        break;

      case 'user_agree_tr':
        {
          stCont = 3;
          lang = 'tr';
        }
        break;
      case 'user_agree_en':
        {
          stCont = 3;
          lang = 'en';
        }
        break;

      case 'garanti_tr':
        {
          stCont = 4;
          lang = 'tr';
        }
        break;
      case 'garanti_en':
        {
          stCont = 4;
          lang = 'en';
        }
        break;

      case 'iyzico_agree_tr':
        {
          stCont = 5;
          lang = 'tr';
        }
        break;
      case 'iyzico_agree_en':
        {
          stCont = 5;
          lang = 'en';
        }
        break;

      case 'satis_sozlesme_tr':
        {
          stCont = 6;
          lang = 'tr';
        }
        break;
      case 'satis_sozlesme_en':
        {
          stCont = 6;
          lang = 'en';
        }
        break;

      case 'service_agree_tr':
        {
          stCont = 7;
          lang = 'tr';
        }
        break;
      case 'service_agree_en':
        {
          stCont = 7;
          lang = 'en';
        }
        break;

      case 'user_approve_tr':
        {
          stCont = 8;
          lang = 'tr';
        }
        break;
      case 'user_approve_en':
        {
          stCont = 8;
          lang = 'en';
        }
        break;

      case 'faq_tr':
        {
          stCont = 9;
          lang = 'tr';
        }
        break;
      case 'faq_en':
        {
          stCont = 9;
          lang = 'en';
        }
        break;

      case 'kvkk_tr':
        {
          stCont = 10;
          lang = 'tr';
        }
        break;
      case 'kvkk_en':
        {
          stCont = 10;
          lang = 'en';
        }
        break;

      case 'eposta_pazarlama_tr':
        {
          stCont = 14;
          lang = 'tr';
        }
        break;
      case 'eposta_pazarlama_en':
        {
          stCont = 14;
          lang = 'en';
        }
        break;

      case 'sms_pazarlama_tr':
        {
          stCont = 15;
          lang = 'tr';
        }
        break;
      case 'sms_pazarlama_en':
        {
          stCont = 15;
          lang = 'en';
        }
        break;

      case 'on_bilgilendirme_tr':
        {
          stCont = 16;
          lang = 'tr';
        }
        break;
      case 'on_bilgilendirme_en':
        {
          stCont = 16;
          lang = 'en';
        }
        break;
      default: {
        if (pageName) {
          const pageId = +pageName.split('_')[0];
          stCont = pageId;
        }
        lang = this.languageService.selected;
      }
    }
    this.pageDetails.next({
      stCont: stCont,
      lang: lang
    });
  }
  getStaticContent(): Observable<StaticContentType> {
    return this._httpClient
      .get<StaticContentType>('/api/bobvale/getstaticcontent?tip=' + this.pageDetails.value.stCont + '&lang=' + this.pageDetails.value.lang)
      .pipe(
        tap(response => {
          if (response) {
            this.pageStaticContentResponse.next(response);
          }
        })
      );
  }
}
