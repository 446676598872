import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { AlertService, AuthService, Direction, SpinnerService } from '@bob/service';
import { environment } from '@bob/api';
import { Router } from '@angular/router';

// export const PyroInterceptor = (
//   req: HttpRequest<unknown>,
//   next: HttpHandler,
//   _intercept: BobInterceptor,
//   _authService: AuthService,
//   _toastrService: AlertService,
//   _router: Router,
//   _spinnerService: SpinnerService
// ): Observable<HttpEvent<unknown>> => {

@Injectable()
export class PyroInterceptor implements HttpInterceptor {
  private _authService = inject(AuthService);
  private _intercept = inject(BobInterceptService);
  private _toastrService = inject(AlertService);
  private _spinnerService = inject(SpinnerService);
  private _router = inject(Router);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const _hatalariGosterme = ['/api/lobi/ping'];
    const refreshTokenInProgress$ = this._intercept.refreshTokenInProgress$;

    req = this._intercept.getNewRequest(req);

    const _isAnonimousRequest = this._intercept.isAnonimousRequest(req);

    this._spinnerService.except = [
      '/api/bobvale/GetVersionCheck',
      '/api/bobvale/CheckServiceStatus',
      '/api/transfer/GetReservationPaymentStatus',
      '/api/Hesap/RefreshToken'
    ];
    this._spinnerService.visible(req, Direction.add);

    let tokenRequest$ = next.handle(req);

    if (!_isAnonimousRequest) {
      tokenRequest$ = from(this._intercept.addRequestToken(req)).pipe(
        switchMap(accessToken => {
          if (!accessToken) {
            // Eğer token yoksa 403 hatası
            if (this._intercept.debugMode) console.log('istek potada', req.url);
            return throwError(() => new HttpErrorResponse({ status: 202, statusText: 'Accepted' }));
          }

          // Token varsa isteğe ekle
          const newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
          });
          return next.handle(newReq);
        }),
        catchError(error => {
          // Catch "401 Unauthorized" responses
          if (error instanceof HttpErrorResponse && error.status === 202) {
            // Token süresi dolmuşsa yenile
            if (!refreshTokenInProgress$.value) {
              // Eğer yenileme yapılmıyorsa başlat
              this._intercept.refreshTokenInProgress$.next(true);
              return from(this._authService.getValidToken()).pipe(
                tap(newToken => {
                  if (!newToken) {
                    // Eğer refresh başarısızsa çıkış yap
                    // this._authService.logout();
                    // this._router.navigate(['/sign-out']);
                  }
                }),
                switchMap(accessToken => {
                  refreshTokenInProgress$.next(false);
                  const retryReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
                  });
                  return next.handle(accessToken ? retryReq : req);
                }),
                catchError(refreshError => {
                  refreshTokenInProgress$.next(false);
                  // this._authService.logout();
                  // this._router.navigate(['/sign-out']);
                  return throwError(refreshError);
                })
              );
            } else {
              // Eğer yenileme yapılırken başka istek gelirse beklet
              return refreshTokenInProgress$.pipe(
                filter(inProgress => !inProgress),
                take(1),
                switchMap(() => from(this._intercept.addRequestToken(req))),
                switchMap(accessToken => {
                  const retryReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
                  });
                  return next.handle(accessToken ? retryReq : req);
                })
              );
            }
          }

          return throwError(error);
        })
      );
    } else {
      if (this._intercept.debugMode) console.log('Pyro --> ', req.url);
    }

    return tokenRequest$
      .pipe(
        tap(
          async () => {
            // if (response instanceof HttpResponse<any>) console.log(response.url, response.status);
          },
          async (error: HttpErrorResponse) => {
            let mesaj: string;

            let url = error.url ?? '';

            if (_hatalariGosterme.some(x => url.indexOf(x) !== -1)) {
              return;
            }
            switch (error.status) {
              case 0:
                mesaj = 'apiye ulaşılamadı.';
                this._toastrService.presentToast(mesaj);
                break;

              case 400:
                mesaj = error.error?.Message;
                if (!mesaj) {
                  mesaj = error.error.errors;
                }
                this._toastrService.presentToast(mesaj);
                console.log('hata', mesaj);

                break;

              case 401:
                mesaj = 'Giriş yapmanız gerekmektedir.';
                this._toastrService.presentToast(mesaj);
                this._authService.logout();
                break;

              case 403:
                mesaj = error.error?.Message;
                this._toastrService.presentToast(mesaj);
                this._authService.logout();
                break;

              default:
                mesaj = error.error?.Message;
                if (!mesaj) mesaj = error?.message;
                if (!mesaj) mesaj = error.error?.title;

                this._toastrService.presentToast(mesaj);
                break;
            }
          }
        )
      )
      .pipe(
        finalize(async () => {
          this._spinnerService.visible(req, Direction.remove);
        })
      );
  }
}

@Injectable({ providedIn: 'root' })
export class BobInterceptService {
  public refreshTokenInProgress$ = new BehaviorSubject<boolean>(false);
  public debugMode = false;

  private _authService = inject(AuthService);

  constructor() {}

  isAnonimousRequest(req: HttpRequest<any>): boolean {
    const allowedAnonUrls = [
      '/api/Hesap/Token',
      '/api/Hesap/RefreshToken',
      '/api/JobNotification/SendPassword',
      '/api/transfer/KopruOtoyolListesi',
      '/api/transfer/gethizmet101',
      '/api/transfer/GetVehicleList',
      '/api/bobvale/getstaticcontent'
    ];

    const restrictedSegments = ['mockapi/', 'odata/', 'hub/', 'api/'];

    // Anonim URL kontrolü
    const isAllowedAnonUrl = allowedAnonUrls.some(url => req.url.indexOf(url) > -1);

    // Domain kontrolü
    const hasDomain = req.url.indexOf('bob.com.tr') > -1 || req.url.indexOf('localhost') > -1;

    // Kısıtlı segment kontrolü
    const hasRestrictedSegment = restrictedSegments.some(segment => req.url.indexOf(segment) > -1);

    // Anonim karar mekanizması
    if (isAllowedAnonUrl) {
      return true;
    } else if (!hasDomain) {
      return true;
    } else if (hasDomain && !hasRestrictedSegment) {
      return true;
    }

    return false;
  }

  async addRequestToken(req: HttpRequest<any>): Promise<string | null> {
    let token = await this._authService.getValidToken();

    if (this.debugMode) {
      if (!token) {
        console.log(`%c Auth Token Yok --> %c (${req.url})`, 'background: #000; color: #f39300', 'background: #000; color: #995555');
      } else {
        console.log(`%c Auth --> ${req.url}  %c (${(token ?? 'null').slice(0, 20)})`, 'background: #000; color: #bada55', 'background: #000; color: #995555');
      }
    }
    return token;
  }

  getNewRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.search('googleapis') > -1) {
      return request;
    }
    if (request.url.indexOf('http') !== -1) {
      return request;
    }
    if (!(request.url.indexOf('api') !== -1 || request.url.indexOf('odata') !== -1)) {
      return request;
    }

    let domain = environment.newApi;
    domain = domain.slice(-1) === '/' ? domain.substring(0, domain.length - 1) : domain;
    let api = request.url;
    api = api.substring(0, 1) === '/' ? api.substring(1, api.length) : api;

    const url = `${domain}/${api}`;

    const httpHeaders = {
      // eslint-disable-next-line quote-props
      accept: 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json; charset=utf-8'
    };

    request = request.clone({ setHeaders: httpHeaders, url });

    return request;
  }
}
