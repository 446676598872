import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { CustomerModel, DocumentApproveModel } from '../models/auth.model';
import { NavController, Platform } from '@ionic/angular';
import { Token } from '@capacitor/push-notifications';
import { HesapService, JobNotificationService, JwtModel, PhoneTokenModel, PhoneTokenType } from '@bob/api';
import { AlertService, LanguageService, StorageService } from '@bob/service';
import { TranslateService } from '@ngx-translate/core';

export type authStatus = 'true' | 'false' | 'register';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // public isAuthenticated: BehaviorSubject<authStatus | null> = new BehaviorSubject<authStatus | null>('false');
  private customer: CustomerModel | undefined;
  private decodedToken?: JwtModel;

  constructor(
    private _navCtrl: NavController,
    private _platform: Platform,
    private _hesapService: HesapService,
    private _jobNotificationService: JobNotificationService,
    private _storageService: StorageService,
    private _alertService: AlertService,
    private _translate: TranslateService,
    private _lang: LanguageService
  ) {}

  public async getCustomer(): Promise<CustomerModel | undefined> {
    return await this._storageService.get({ key: 'customer' }).then(response => {
      if (!response?.value) {
        return undefined;
      }
      const user = JSON.parse(response.value ?? '');
      return new CustomerModel(user);
    });
  }

  public async isAuthenticated(): Promise<authStatus> {
    const token = await this.getDecodedToken();
    if (!token) {
      return 'false';
    }
    if (!token.name) {
      this._navCtrl.navigateRoot(`/registration`);
      return 'register';
    }

    return 'true';
  }

  public login(telNo: string, pass: string, countryCode: string): Promise<authStatus> {
    return new Promise<authStatus>(resolve => {
      if (!pass) {
        this._alertService.presentToast(this._translate.instant('alert.invalid_pass'));
        resolve('false');
        return;
      }

      this._hesapService.token(telNo, pass).subscribe(async response => {
        if (response) {
          if (response.access_token) {
            await this._storageService.set({
              key: 'access_token',
              value: response.access_token
            });
          }
          if (response.refreshToken) {
            await this._storageService.set({
              key: 'refreshToken',
              value: response.refreshToken
            });
          }

          let user = {
            telefon: telNo,
            password: pass
          };

          await this._storageService.set({
            key: 'customer',
            value: JSON.stringify(user)
          });

          this.decodedToken = await this.getDecodedToken();
          if (!this.decodedToken) {
            resolve('false');
            return;
          }
          this.getCustomer();

          let docApprove = new DocumentApproveModel({ gam: this.decodedToken.gam, rp: this.decodedToken.rp });
          await this._storageService.set({
            key: 'doc_approve_model',
            value: JSON.stringify(docApprove)
          });

          if (this.decodedToken.birey) {
            await this.getUserDetailFromOldApi(countryCode, pass);
          }

          return resolve(this.isAuthenticated());
        }
      });
    });
  }

  public async refreshToken(): Promise<string | null> {
    let refreshToken = await this._storageService.get({ key: 'refreshToken' });

    if (!refreshToken || !refreshToken.value) {
      return null;
    }

    let accessToken = await this._storageService.get({ key: 'access_token' });

    if (!accessToken || !accessToken.value) {
      return null;
    }

    let newToken = await this._hesapService.refreshToken(refreshToken.value);

    if (!newToken || !newToken.access_token || !newToken.refreshToken) {
      return null;
    }

    this.customer = await this.getCustomer();
    if (!this.customer) {
      return null;
    }

    await this._storageService.set({ key: 'access_token', value: newToken.access_token });
    await this._storageService.set({ key: 'refreshToken', value: newToken.refreshToken });
    this.decodedToken = await this.getDecodedToken();
    if (!this.decodedToken) {
      return null;
    }
    if (this.decodedToken.birey) {
      await this.getUserDetailFromOldApi('', this.customer.password);
    }
    return newToken.access_token;
  }

  public async logout() {
    console.log('logout');

    let tel = await this._storageService.get({ key: 'bobv_telNo' });

    localStorage.clear();
    await this._storageService.clear();
    this.customer = new CustomerModel({});

    console.log('logout', tel.value);

    this._storageService.set({ key: 'bobv_telNo', value: tel.value });

    this._navCtrl.navigateRoot('home');
  }

  public async saveFCMToken(token: Token) {
    // ⚡️  [log] - ["ipad","ios","tablet","cordova","capacitor","mobile","hybrid"] for ipad
    // ⚡️  [log] - ["iphone","ios","cordova","capacitor","mobile","hybrid"] for iphone

    const isAuth = await this.isAuthenticated();
    if (isAuth !== 'true') {
      setTimeout(() => this.saveFCMToken(token), 30000); //wait 30secs before checking again.
      return;
    }

    if (!this.customer) {
      setTimeout(() => this.saveFCMToken(token), 30000); //wait 30secs before checking again.
      return;
    }

    let isMobile = true;
    const platforms = this._platform.platforms();
    if (platforms.find(x => x === 'ipad') !== undefined || platforms.find(x => x === 'tablet') !== undefined) {
      isMobile = false;
    }

    let platform = PhoneTokenType.web; // Default olarak Web'i seçiyoruz

    const isPlatformIos = platforms.find(x => ['iphone', 'ios', 'ipad'].includes(x));
    const isPlatformAndroid = platforms.find(x => ['android'].includes(x));

    if (isPlatformIos !== undefined) {
      platform = PhoneTokenType.ios;
    } else if (isPlatformAndroid !== undefined) {
      platform = PhoneTokenType.android;
    }

    const params = new PhoneTokenModel();
    params.token = token.value;
    params.isPhone = isMobile;
    params.tipi = platform;

    // this.httpn.setDataSerializer('json')
    const response = await this._jobNotificationService.savePhoneToken(params).toPromise();

    if (response) {
      this.customer.fcmToken = token.value;
      await this._storageService.set({ key: 'fcmToken', value: token.value });
    } else {
      setTimeout(() => this.saveFCMToken(token), 30000); //wait 30secs before checking again.
    }
  }

  private deletePhoneToken(token: PhoneTokenModel): Observable<boolean> {
    return this._jobNotificationService.deletePhoneToken(token);
  }

  public sendPasswordNew(phone: string): Observable<boolean> {
    return this._jobNotificationService.sendPasswordNew(phone);
  }

  private async getUserDetailFromOldApi(countryCode: string, pass: string): Promise<void> {
    const res = await this._jobNotificationService.getUserDetail();
    if (!res) return;
    if (res.status) {
      const user = JSON.parse(JSON.stringify(res));

      user.countryCode = countryCode;
      user.password = pass;

      await this._storageService.set({
        key: 'customer',
        value: JSON.stringify(user)
      });
      this.customer = user;
      let ret = !(user.ad && user.email);

      if (ret) {
        this._navCtrl.navigateRoot(`/registration`);
      }

      const url = await this._storageService.get({ key: 'loginReturnUrl' });
      if (url.value) {
        this._navCtrl.navigateRoot(url.value);
        await this._storageService.remove({ key: 'loginReturnUrl' });
      }
    } else {
      if (this._lang.selected === 'en') {
        this._alertService.presentToast(res.messageEn);
      } else {
        this._alertService.presentToast(res.message);
      }
    }
  }

  private async getDecodedToken(): Promise<JwtModel | undefined> {
    let token = (await this._storageService.get({ key: 'access_token' })).value;
    console.warn('getDecodedToken', token);

    if (!token) return undefined;
    if (token) return jwt_decode<JwtModel>(token ?? '');
    return new JwtModel();
  }

  private async isTokenExpired(): Promise<boolean> {
    // Get the expiration date
    const date = await this.getTokenExpirationDate();

    if (!date) {
      return true;
    }

    // Check if the token is expired
    return !(date.valueOf() > new Date().valueOf() + 30000);
  }

  private async getTokenExpirationDate(): Promise<Date | undefined> {
    const decodedToken = await this.getDecodedToken();
    if (!decodedToken) return undefined;
    if (!decodedToken.hasOwnProperty('exp')) {
      return undefined;
    }

    // Convert the expiration date
    const date = new Date(0);
    date.setUTCSeconds(decodedToken.exp ?? 0);

    return date;
  }

  public async getValidToken(): Promise<string | null> {
    let token = await this._storageService.get({ key: 'access_token' });

    if (!token || !token.value) {
      return null;
    }

    const isExpired = await this.isTokenExpired();
    if (!isExpired) {
      return token.value;
    }

    return null;
  }
}
