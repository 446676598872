import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { BaseResponse, hizmetlerModel, JobModelReq } from '@bob/api';
import * as model from '../models/transfer.model';

@Injectable({
  providedIn: 'root'
})
export class TransferApiService {
  constructor(private _httpClient: HttpClient) {}

  checkRewardProgramNew(model: model.CheckRewardProgramNewRequestModel): Observable<BaseResponse<model.CheckRewardProgramNewResponseModel>> {
    return this._httpClient.post<BaseResponse<model.CheckRewardProgramNewResponseModel>>('/api/transfer/CheckRewardProgramNew', model);
  }

  addReservation(body: model.AddReservationModel): Observable<BaseResponse<model.AddReservationDataRespModel>> {
    return this._httpClient.post<BaseResponse<model.AddReservationDataRespModel>>('/api/transfer/AddReservation', body);
  }

  addReservation2(body: model.AddReservationRequestModel2): Promise<model.OdemeSonucuModel> {
    return lastValueFrom(this._httpClient.post<model.OdemeSonucuModel>('/api/transfer/AddReservation2', body));
  }

  getKopruOtoyolListesi(): Promise<BaseResponse<model.KopruOtoyolDataModel[]>> {
    return lastValueFrom(this._httpClient.get<BaseResponse<model.KopruOtoyolDataModel[]>>('/api/transfer/KopruOtoyolListesi'));
  }

  getAllEndedJobs(body: JobModelReq): Observable<hizmetlerModel[]> {
    return this._httpClient.post<hizmetlerModel[]>('/api/bobvale/GetMyEndedJobs', body);
  }
  getAllCanceledJobs(body: JobModelReq): Observable<hizmetlerModel[]> {
    return this._httpClient.post<hizmetlerModel[]>('/api/bobvale/GetMyCanceledJobs', body);
  }

  getUserId(email: string): Observable<model.GetUserRespModel> {
    return this._httpClient.get<model.GetUserRespModel>('/api/transfer/GetUserId?email=' + email).pipe();
  }

  getReservationPaymentStatus(body: model.ReservationPaymentModel): Observable<model.GetUserRespModel> {
    return this._httpClient.post<model.GetUserRespModel>('/api/transfer/GetReservationPaymentStatus', body).pipe();
  }

  driverSurvey(body: model.DriverSurveyReq): Observable<model.DriverSurveyModel> {
    return this._httpClient.post<model.DriverSurveyModel>('/api/transfer/DriverSurvey', body).pipe();
  }

  cancelReservation(body: model.CancelReservationReq): Observable<model.GetUserRespModel> {
    return this._httpClient.post<model.GetUserRespModel>('/api/transfer/CancelReservation', body);
  }

  getDriverDetails(rezId: string): Observable<model.GetDriverRespModel> {
    return this._httpClient.get<model.GetDriverRespModel>('/api/transfer/GetDriverDetails?RezId=' + rezId);
  }

  getCheckFlight(code: string, srvsDate: string): Observable<model.CheckFlightModel> {
    return this._httpClient.get<model.CheckFlightModel>('/api/transfer/CheckFlight?ucusno=' + code + '&tarih=' + new Date(srvsDate).toISOString());
  }

  postVehicleList(data: model.GetVehicleListRequestModel): Observable<model.VehicleListRespModel> {
    return this._httpClient.post<model.VehicleListRespModel>('/api/transfer/GetVehicleList', data);
  }

  getHavalimaniKapi(portName: string): Observable<model.HavalimaniKapiRespModel> {
    return this._httpClient.get<model.HavalimaniKapiRespModel>('/api/transfer/GetHavalimaniKapi?havalimani=' + portName);
  }

  getRentalPackages(region: string, serviceType: string): Observable<model.GetHizmetRespModel> {
    const hizmetTip = serviceType === 'transfer' ? 100 : 101;
    return this._httpClient.get<model.GetHizmetRespModel>(
      encodeURI(`/api/transfer/gethizmet101?firmaid=e614feda-294b-4b8c-9b27-4e1ce6d57eca&sehir=${region}&hizmettip=${hizmetTip}`)
    );
  }
}
