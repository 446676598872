import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { KrediKartiModel, KrediKartiKaydetModel, KartSilModel, CariOlusturModel, CariDetayModel } from '../models/satis.types';

@Injectable({
  providedIn: 'root'
})
export class SatisApiService {
  constructor(private _httpClient: HttpClient) {}

  public async kartOlustur(krediKarti: KrediKartiKaydetModel): Promise<KrediKartiModel> {
    return await lastValueFrom(this._httpClient.post<KrediKartiModel>('/api/Satis/KartOlustur', krediKarti));
  }

  public async kartlariGetir(): Promise<KrediKartiModel[]> {
    return await lastValueFrom(this._httpClient.get<KrediKartiModel[]>('/api/Satis/KartlariGetir'));
  }

  public async krediKartiSil(kartSil: KartSilModel): Promise<boolean> {
    return await lastValueFrom(this._httpClient.post<boolean>('/api/Satis/KrediKartiSil', kartSil));
  }

  public async varsayilanKartSec(kartId: string): Promise<void> {
    return await lastValueFrom(this._httpClient.get<void>(`/api/Satis/VarsayilanKartSec/${kartId}`));
  }

  public async cariOlustur(cari: CariOlusturModel): Promise<CariDetayModel> {
    return await lastValueFrom(this._httpClient.post<CariDetayModel>('/api/Satis/CariOlustur', cari));
  }

  public async cariSil(cariId: number): Promise<void> {
    return await lastValueFrom(this._httpClient.delete<void>(`/api/Satis/CariSil/${cariId}`));
  }

  public async carileriGetir(): Promise<CariDetayModel[]> {
    return await lastValueFrom(this._httpClient.get<CariDetayModel[]>('/api/Satis/CarileriGetir'));
  }

  public async varsayilanCariSec(cariId: number): Promise<void> {
    return await lastValueFrom(this._httpClient.get<void>(`/api/Satis/VarsayilanCariSec/${cariId}`));
  }

  public async completeTransaction(body: any): Promise<any> {
    return await lastValueFrom(this._httpClient.post<any>('/api/Satis/completeTransaction', body));
  }
}
